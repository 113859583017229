import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"height":"100%"},
    class: _normalizeClass({
      'is-emphasis-first-line': _ctx.isEmphasisFirstLine,
      'is-frame-ready': _ctx.frameRect,
    })
  }, [
    (_ctx.place && _ctx.congestions && _ctx.lastUpdated)
      ? (_openBlock(), _createBlock(_component_VueApexCharts, {
          key: 0,
          ref: "chart",
          type: "heatmap",
          height: "100%",
          options: _ctx.chartOptions,
          series: _ctx.chartSeries
        }, null, 8, ["options", "series"]))
      : _createCommentVNode("", true),
    (_ctx.frameRect)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "first-series-frame",
          style: _normalizeStyle(_ctx.frameRectStyle)
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.frameRect)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "first-series-frame-panel",
          style: _normalizeStyle(_ctx.frameRectPanelStyle)
        }, null, 4))
      : _createCommentVNode("", true)
  ], 2))
}