import { createStore } from 'vuex'

const BASE_URL = process.env.VUE_APP_API_BASE_URL || '';

export default createStore({
  state: {
    place: null,
    congestions: null,
    currentStatus: null,
    currentTime: null,
    lastUpdated: null,
    windowWidth: window.innerWidth,
    debugCurrentTime: null,
    showCredit: false,
    notFound: false,
    utmSource: null,
  },
  mutations: {
    setPlace(state, place) {
      state.place = place
    },
    setCongestions(state, congestions) {
      state.congestions = congestions
    },
    setLastUpdated(state, lastUpdated) {
      state.lastUpdated = lastUpdated
    },
    setCurrentStatus(state, status) {
      state.currentStatus = status
    },
    setCurrentTime(state, time) {
      state.currentTime = time
    },
    windowWidth(state, width) {
      state.windowWidth = width
    },
    setDebugCurrentTime(state, time) {
      state.debugCurrentTime = time
    },
    setShowCredit(state, show) {
      state.showCredit = show
    },
    setNotFound(state, notFound) {
      state.notFound = notFound
    },
    setUtmSource(state, utmSource) {
      state.utmSource = utmSource
    },
  },
  actions: {
    async loadPlace({commit, state}, code) {
      const response = await fetch(BASE_URL + '/api/place/' + code + (state.debugCurrentTime ? `?debug_current_time=${state.debugCurrentTime}` : ''))
      if (response.status == 404) {
        commit('setNotFound', true)
        return
      }

      const json = await response.json()
      commit('setPlace', json.place)
      commit('setCongestions', json.congestions)
      commit('setCurrentStatus', json.current_status)
      commit('setCurrentTime', json.current_time)
      commit('setLastUpdated', json.last_updated)
    },
  },
  modules: {
  },
  getters: {
    isPC(state) {
      return 480 <= state.windowWidth
    },
    isSP(state) {
      return 480 > state.windowWidth
    },
    isDateReverse(state) {
      return state.utmSource == 'huistenbosch' ||
      state.utmSource === "huistenbosch1" ||
      state.utmSource === "huistenbosch2"
    },
    isEmphasisFirstLine(state) {
      return state.utmSource == 'huistenbosch' ||
      state.utmSource === "huistenbosch1" ||
      state.utmSource === "huistenbosch2"
    },
  },
})
