
import { defineComponent } from 'vue';
import { mapState, mapGetters } from "vuex";
import VueApexCharts from "vue3-apexcharts";

import dayjs from 'dayjs'
import "dayjs/locale/ja";

dayjs.locale("ja");

import chartConfig from "../../config.json";
interface ChartConfig {
  from: number;
  to: number;
  name: string;
  color: string;
}

export default defineComponent({
  name: "Chart",
  components: {
    VueApexCharts,
  },
  props: {
    height: Number,
  },
  data() {
    return {
      frameRect: new DOMRect(0, 0, 0, 0) as DOMRect,
      //config: [] as ChartConfig[]
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (
        this.isEmphasisFirstLine &&
        this.$refs.chart &&
        // @ts-ignore
        this.$refs.chart.chart
      ) {
        // @ts-ignore
        this.$refs.chart.chart.addEventListener("mounted", (c: any) =>
          this.refreshFrame(c)
        );
        // @ts-ignore
        this.$refs.chart.chart.addEventListener("updated", (c: any) =>
          this.refreshFrame(c)
        );
      }

      if (this.place.level === 0) {
        this.config = chartConfig.threelevels.default;
      }

      if (this.place.level === 1) {
        if (this.utmSource === "huistenbosch1") {
          this.config = chartConfig.fivelevels.huistenbosch1;
        } else if (this.utmSource === "huistenbosch2") {
          this.config = chartConfig.fivelevels.huistenbosch2;
        } else {
          this.config = chartConfig.fivelevels.default;
        }
      }
    });

    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    timeToMinutes(time: string) {
      const a = time.split(":");
      const h = Number(a[0]);
      const m = Number(a[1]);
      return h * 60 + m;
    },
    refreshFrame(chart: { el: HTMLCanvasElement }) {
      setTimeout(() => {
        const firstSeries = chart.el.querySelector(".apexcharts-heatmap-series");
        this.frameRect = firstSeries ? firstSeries.getBoundingClientRect() : new DOMRect(0, 0, 0, 0);
      }, 1000);
    },
    handleResize() {
      this.frameRect = new DOMRect(0, 0, 0, 0);
    },
  },
  computed: {
    ...mapState([
      "congestions",
      "place",
      "lastUpdated",
      "showCredit",
      "windowWidth",
      "utmSource",
    ]),
    ...mapGetters(["isPC", "isDateReverse", "isEmphasisFirstLine"]),
    config() {
      let conf: ChartConfig[] = [];
      if (this.place.level === 0) {
        conf = chartConfig.threelevels.default;
      }
      if (this.place.level === 1) {
        if (this.utmSource === "huistenbosch1") {
          conf = chartConfig.fivelevels.huistenbosch1;
        } else if (this.utmSource === "huistenbosch2") {
          conf = chartConfig.fivelevels.huistenbosch2;
        } else {
          conf = chartConfig.fivelevels.default;
        }
      }
      return conf;
    },
    frameRectStyle() {
      return {
        top: this.frameRect.top + "px",
        left: 15 - 1 + "px",
        width: this.frameRect.left - 15 + this.frameRect.width + 1 + "px",
        height: this.frameRect.height + "px",
        border: this.frameRect.top > 0 ? "3px solid #092b70" : "none"
      };
    },
    frameRectPanelStyle() {
      return {
        top: this.frameRect.top + "px",
        left: "15px",
        width: this.frameRect.left - 15 + "px",
        height: this.frameRect.height + "px",
      };
    },
    chartSeries() {
      if (!this.congestions) {
        return [];
      }

      const trends = this.congestions.map((c: { time: Date | string; status: string | boolean; }) => {
          const d = dayjs(c.time);
          return {
            date: d.format("YYYY-MM-DD"),
            d: d,
            status: c.status,
          };
      });

      const grouped = {} as any;

      for (const trend of trends) {
        grouped[trend.date] = grouped[trend.date] || [];
        grouped[trend.date].push(trend);
      }

      return Object.keys(grouped)
        .sort()
        .reverse()
        .map((date) => {
          const trends = grouped[date];
          const d = new Date(date);
          const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"][
            d.getDay()
          ];

          return {
            name: d.getMonth() + 1 + "/" + d.getDate() + " (" + dayOfWeek + ")",
            data: trends.map((trend: any) => {
              if (this.place.level == 1) {
                return {
                  x: trend.d.format("H:mm"),
                  y: { 不明: 0, 閑散: 1, 空いている: 2, 通常: 3, 混雑: 4, とても混雑: 5 }[trend.status as string],
                };
              }
              return {
                x:
                  this.place.tile_unit == 60
                    ? trend.d.hour() + "時"
                    : trend.d.format("H:mm"),
                    y: { 不明: 0, 閑散: 1, 通常: 2, 混雑: 3 }[trend.status as string],
              };
            }),
          };
        });
    },
    chartOptions() {
      let labelFontSize = this.isPC ? "10px" : "7px";

      if (this.showCredit) {
        // 320px幅で時間帯ラベル16個表示時7pxを基準に最大9px(SP) or 12px(PC)まで上げられるだけ上げる
        const labelCount =
          this.chartSeries[0].data.length / (60 / this.place.tile_unit);
        const f = this.windowWidth / labelCount / (320 / 16);
        let s = Math.floor(7 * f);
        s = Math.min(this.isPC ? 12 : 9, s);
        s = Math.max(this.isPC ? 10 : 7, s);
        labelFontSize = s + "px";
      }

      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          style: {
            fontSize: "11px",
          },
          y: {
            formatter: (t: any) => {
              let labels: string[] = [];
              if (this.place.level === 0) {
                labels = chartConfig.threelevels.default.map((item) => item.name.replace("…", ""));
              }

              if (this.place.level === 1) {
                if (this.utmSource === "huistenbosch1") {
                  labels = chartConfig.fivelevels.huistenbosch1.map((item) => item.name.replace("…", ""));
                } else if (this.utmSource === "huistenbosch2") {
                  labels = chartConfig.fivelevels.huistenbosch2.map((item) => item.name.replace("…", ""));
                } else {
                  labels = chartConfig.fivelevels.default.map((item) => item.name.replace("…", ""));
                }
              }
              return labels[t];
            },
            title: {
              formatter: (t: any, c: any) => {
                const xLabel = c.w.globals.labels[c.dataPointIndex];
                return t + " " + xLabel;
              },
            },
          },
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: this.config,
            },
            enableShades: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          position: "top",
          labels: {
            rotate: 0,
            style: {
              fontSize: labelFontSize,
            },
            offsetY: this.isPC ? -2 : -3,
            formatter: (t: any, c: any, i: any) => {
              if (i === undefined) {
                return t;
              } else {
                if (this.place.tile_unit == 60) {
                  return t;
                } else {
                  return t.match(/[0-9]{1,2}:00$/)
                    ? t.replace(/:00/, "時")
                    : "";
                }
              }
            },
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          reversed: this.isDateReverse,
          labels: {
            style: {
              fontSize: labelFontSize,
            },
            offsetX: this.isPC ? 0 : 3,
          },
        },
        legend: {
          position: "bottom",
          fontSize: "12px",
          fontFamily:
            '"游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif',
          fontWeight: "bold",
        },
      };
    },
  },
});
