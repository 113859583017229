import config from "../../config.json"

export default {
  statusClass(status: string) {
    return {
      '不明': 'is-status-0',
      '閑散': 'is-status-1',
      '通常': 'is-status-2',
      '混雑': 'is-status-3',
      '空いている': 'is-status-4',
      'とても混雑': 'is-status-5',
    }[status]
  },
  statusDetailLabel(status: string) {
    return {
      '不明': config.statusDetailLabel["不明"],
      '閑散': config.statusDetailLabel["閑散"],
      '通常': config.statusDetailLabel["通常"],
      '混雑': config.statusDetailLabel["混雑"],
      '空いている': config.statusDetailLabel["空いている"],
      'とても混雑': config.statusDetailLabel["とても混雑"],
    }[status]
  },
  statusDetailHeader(status: string) {
    return {
      '不明': 'データ不足',
      '閑散': '直近の状況',
      '通常': '直近の状況',
      '混雑': '直近の状況',
      '空いている': '直近の状況',
      'とても混雑': '直近の状況',
    }[status]
  },
}
