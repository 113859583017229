
import { defineComponent } from "vue";

import "normalize.css";
export default defineComponent({
  name: "App",
  methods: {
    handleResize: function () {
      this.$store.commit("windowWidth", window.innerWidth);
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount: function () {
    window.removeEventListener("resize", this.handleResize);
  },
});
