
import { defineComponent } from "vue";

import { mapState, mapActions } from "vuex";
import Chart from "../components/Chart.vue";

import dayjs from "dayjs";
import statusHelper from "../lib/StatusHelper";
import chartConfig from '../../config.json';

export default defineComponent({
  name: "Place",
  components: {
    Chart,
  },
  data() {
    return {
      intervalId: null as any,
    };
  },
  computed: {
    ...mapState([
      "place",
      "congestions",
      "currentStatus",
      "currentTime",
      "lastUpdated",
      "showCredit",
      "notFound",
      "utmSource",
    ]),
    lastUpdateDateTime() {
      return dayjs(this.lastUpdated).format("YYYY/MM/DD HH:mm");
    },
    lastUpdatedLabel() {
      return dayjs(this.currentTime).format("YYYY/MM/DD HH:mm");
    },
  },
  methods: {
    ...mapActions(["loadPlace"]),
    statusClass: statusHelper.statusClass,
    //statusDetailLabel: statusHelper.statusDetailLabel,
    statusDetailLabel: function(status: string) {
      let detailLabels: any = chartConfig.statusDetailLabel.threelevels.default;

      if (this.place.level === 0) {
        detailLabels = chartConfig.statusDetailLabel.threelevels.default;
      }
      if (this.place.level === 1) {
        if (this.utmSource === "huistenbosch1") {
          detailLabels = chartConfig.statusDetailLabel.fivelevels.huistenbosch1;
        } else if (this.utmSource === "huistenbosch2") {
          detailLabels = chartConfig.statusDetailLabel.fivelevels.huistenbosch2;
        } else {
          detailLabels = chartConfig.statusDetailLabel.fivelevels.default;
        }
      }
      return detailLabels[status];
    },
    statusDetailHeader: statusHelper.statusDetailHeader,
    statusStyle: function (status: string) {
      let bgColor: string | undefined = chartConfig.threelevels.default[0].color;
      const mapDatas: any = chartConfig.statusDetailMap;

      if (this.place.level === 0) {
        const stStr = mapDatas.threelevels.default[status];
        bgColor = chartConfig.threelevels.default.find((item) => item.name.replace("…", "") === stStr)?.color;
      }
      if (this.place.level === 1) {
        if (this.utmSource === "huistenbosch1") {
          const stStr = mapDatas.fivelevels.huistenbosch1[status];
          bgColor = chartConfig.fivelevels.huistenbosch1.find((item) => item.name.replace("…", "") === stStr)?.color;
        } else if (this.utmSource === "huistenbosch2") {
          const stStr = mapDatas.fivelevels.huistenbosch2[status];
          bgColor = chartConfig.fivelevels.huistenbosch2.find((item) => item.name.replace("…", "") === stStr)?.color;
        } else {
          const stStr = mapDatas.fivelevels.default[status];
          bgColor = chartConfig.fivelevels.default.find((item) => item.name.replace("…", "") === stStr)?.color;
        }
      }
      return "background-color: " + bgColor + ";";
    },
  },
  mounted() {
    this.intervalId = setInterval(() => {
      if(this.place.code) {
        this.loadPlace(this.place.code);
      }
    }, 30 * 1000);
  },
  beforeUnmount: function () {
    clearInterval(this.intervalId);
  },
});
