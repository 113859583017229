import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Place from '@/views/Place.vue'

import store from '../store'

interface Place {
  id: number,
  display_name: string,
  tile_unit: number,
  code: number
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/place/:code',
    name: 'Place',
    component: Place,
  },
  {
    path: '/spot/:code',
    name: 'Spot',
    component: Place,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('setUtmSource', to.query.utm_source)
  next()
})

router.afterEach((to) => {
  if (to.query.debug_current_time) {
    store.commit('setDebugCurrentTime', to.query.debug_current_time)
  }

  switch (to.name) {
  case 'Place':
  case 'Spot':
    store.commit('setShowCredit', to.name === 'Spot')
    store.dispatch('loadPlace', to.params.code)
    break;
  }
})

store.watch(state => state.place, (place: Place | null) => {
  if (place) {
    const current = router.currentRoute.value;

    switch (current.name) {
    case 'Place':
    case 'Spot':
      document.title = place.display_name || 'Wifi混雑判定'
      break;
    }
  }
})

export default router
